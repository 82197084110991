@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "IBM Plex Sans",sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.backgound-blue {
  background-color: #29abe2;
}

.font-ibm {
  font-family: "IBM Plex Sans";
}

.font-italic {
  font-style: italic;
 }

 .custom-border {
   border: "5px solid red";
 }

 /* Assessment Colors  */
 .self-direction {
   background-color: #58c1a7
 }

 .hedonism {
  background-color: #ffcd52
 }

 .power {
  background-color: #eb5363
}

.stimulation {
 background-color: #a2ce66
}

.achievement {
 background-color: #f36c53
}

.universalism {
  background-color: #4bbfe9
 }
 
.security {
  background-color: #636d77
}

.benevolence {
background-color: #6898cf
}

.conformity {
background-color: #e888b8
}

.tradition {
background-color: #a491c4
}


/* ================================================================ */

.border-self-direction {
  border-color: #58c1a7
}

.border-hedonism {
  border-color: #ffcd52
}

.border-power {
  border-color: #eb5363
}

.border-stimulation {
  border-color: #a2ce66
}

.border-achievement {
  border-color: #f36c53
}

.border-universalism {
  border-color: #4bbfe9
}

.border-security {
  border-color: #636d77
}

.border-benevolence {
  border-color: #6898cf
}

.border-conformity {
  border-color: #e888b8
}

.border-tradition {
  border-color: #a491c4
}


/* =========================================================================== */

.logo-img{
  min-width: 280px;
  max-width: 280px;
  min-height: 130px;
  max-height: 130px;
}

.logo-img-header{
  min-width: 310px;
  max-width: 310px;
  min-height: 40px;
  max-height: 40px;
}


  /**
   * PurgeCSS:
   * text-red-500
   * text-green-500
   * text-yellow-500
   * text-gray-500
   * text-purple-500
   * text-indigo-500
   * text-blue-500
   * text-pink-500
   *

   */

  .preview-snippet {
    width: 100%;
    height: 400px;
    overflow: hidden;
  }

  .glass-wrapper {
    position: relative;
  }
  
  .glass {
      position: absolute;
      bottom: 0px;
      width: 100%;
      margin-right: 2%;
      margin-top: 2%;
      float: left;
      height: 400px;
      overflow: hidden;
      -webkit-box-shadow: 0px 0px 6px rgba(50, 50, 50, 0.25);
      box-shadow: 0px 0px 6px rgba(50, 50, 50, 0.25);
      backdrop-filter: blur(5px);
      background: rgba(255, 255, 255, 0.4);
      text-align: center;
  }

  .button-over-glass {
    cursor: pointer;
    padding: .6em 1.2em;
    background-color: #29abe2;
    border: 2px solid #3498db;
    border-radius: .2em;
    color: white;
    margin: auto;
    margin: 310px 0px 0px 0px;
    font-weight: 500;
  }

  .logo-glass {
    
  }